import { useEffect, useState } from 'react';
import climbgif from './img/angle4.gif';
import scene1 from './img/1.png';
import scene2 from './img/2.png';
import scene3 from './img/3.png';
import Fireworks from 'react-fireworks';
import McapDisplay from './McapDisplay';
import SocialBubble from './SocialBubble';
import './App.css';
import axios from 'axios';

function App() {
  const [mcap, setMcap] = useState(0);
  const [fireworksVisible, setFireworksVisible] = useState(false);
  const [bubbleVisible, setBubbleVisible] = useState(false);
  const [neonOverlay, setNeonOverlay] = useState("");  // State to track neon overlay

  // Scroll to the bottom when the component mounts
  useEffect(() => {
    window.scrollTo(0, document.documentElement.scrollHeight);  // Scroll to the bottom

    const scrollLogger = setInterval(() => {
      console.log("Current scroll position:", window.scrollY);
    }, 500); 

    return () => clearInterval(scrollLogger);
  }, []);

  const mapMcapToScroll = (mcap) => {
    const maxMcap = 2000000;
    const maxScroll = document.documentElement.scrollHeight - window.innerHeight; 
    return Math.max(maxScroll - (mcap / maxMcap) * maxScroll, 0);
  };

  const handleScroll = (newMcap) => {
    const scrollPosition = mapMcapToScroll(newMcap);
    window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      get_mcap().then(result => {
        if (result) {
          setMcap(result); 
          console.log("Final mcap:", result);

          // Update neon overlay based on mcap thresholds
          if (result >= 2000000) {
            setNeonOverlay("neon-overlay-2M");
          } else if (result >= 1000000) {
            setNeonOverlay("neon-overlay-1M");
          } else if (result >= 500000) {
            setNeonOverlay("neon-overlay-500k");
          } else if (result >= 100000) {
            setNeonOverlay("neon-overlay-100k");
          } else {
            setNeonOverlay("");
          }

          // Fireworks and bubble logic when mcap hits 100k
          if (result >= 100000 && !fireworksVisible) {
            setFireworksVisible(true);
            setBubbleVisible(true);
            setTimeout(() => {
              setBubbleVisible(false); 
            }, 3000);
          }
        }
      });
    }, 1000); 

    return () => clearInterval(interval);
  }, [fireworksVisible]);

  useEffect(() => {
    handleScroll(mcap);
  }, [mcap]);

  return (
    <div className="App">
      {/* Display neon overlay based on market cap range */}
      {neonOverlay && (
        <div className={neonOverlay}>
          <h2>{neonOverlay === "neon-overlay-2M" ? "2M Market Cap" : 
               neonOverlay === "neon-overlay-1M" ? "1M Market Cap" : 
               neonOverlay === "neon-overlay-500k" ? "500K Market Cap" : 
               neonOverlay === "neon-overlay-100k" ? "100K Market Cap" : 
               "Pre-Raydium"}</h2>
        </div>
      )}

      <header className="App-header">
        {/* Header content */}
      </header>

      <McapDisplay mcap={mcap} />
      <SocialBubble />

      <div><img src={scene1} alt="Scene 1" /></div>
      <div><img src={scene2} alt="Scene 2" /></div>
      <div><img src={scene3} alt="Scene 3" /></div>

      <div className="content">
        <div className="ladder">
          <div className="rung"></div>
          <div className="rung"></div>
          <div className="rung"></div>
        </div>
        <div className="ladder2">
          <div className="rung"></div>
          <div className="rung"></div>
          <div className="rung"></div>
        </div>
        <img className="background-gif" src={climbgif} alt="climbing animation" />
      </div>
    </div>
  );
}

// Solscan API code to get the market cap
async function get_price_from_raydium(mint) {
  const url = `https://api-v3.raydium.io/mint/price?mints=${mint}`;

  try {
    const response = await axios.get(url, { headers: { 'accept': 'application/json' } });
    const price = response.data.data[mint];
    return price;
  } catch (error) {
    console.error('Error fetching price from Raydium:', error);
    return null;
  }
}

async function get_price(contractAddress) {
  const url = `https://public-api.dextools.io/trial/v2/token/solana/${contractAddress}/price`;
  const apiKey = "UuwmSHJiZ95JHSf1lKZfY2k681SWdryS4rbGowqE";

  try {
    const response = await axios.get(url, { headers: { "accept": "application/json", "x-api-key": apiKey } });
    return response.data.data.price;
  } catch (error) {
    console.error("Error fetching the token price:", error);
  }
}

// Get market cap by multiplying price by supply
async function get_mcap() {
  const mint1 = 'H6hDKHct9qPuo8Q8x1qkbLafe4EBGbRKnbPM8wXxpump';

  try {
    var price = await get_price_from_raydium(mint1);
    const supply = 1000000000;  // Assume supply for calculation
    if (price && supply) {
      const mcap = price * supply; 
      return mcap;
    } else {
      console.error("Error calculating market cap: Missing price or supply");
      return null;
    }
  } catch (error) {
    console.error('Error calculating market cap:', error);
    return null;
  }
}

export default App;
