import React from 'react';
import './McapDisplay.css'; // Create a CSS file for styling

const McapDisplay = ({ mcap }) => {
  return (
    <div className="mcap-display">
      <h2>Market Cap</h2>
      <p>${mcap.toLocaleString()}</p>
    </div>
  );
};

export default McapDisplay;
