import React from 'react';
import './SocialBubble.css'; // Ensure you have CSS styles in this file
import twitterIcon from './img/x.png'; // Adjust the path if necessary
import facebookIcon from './img/dex.png';
import instagramIcon from './img/tg.png';

const SocialBubble = () => {
  return (
    <div className="social-bubble">
      <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer">
        <img src={twitterIcon} alt="Twitter" />
      </a>
      <a href="https://facebook.com/" target="_blank" rel="noopener noreferrer">
        <img src={facebookIcon} alt="Dexscreener" />
      </a>
      <a href="https://instagram.com/" target="_blank" rel="noopener noreferrer">
        <img src={instagramIcon} alt="Telegram" />
      </a>
      {/* Add more social media links as needed */}
    </div>
  );
};

export default SocialBubble;
